import React, { useState } from "react";
import { Parallax } from "react-parallax";
import Layout from "../../components/layouts";
// import { lightenDarkenColor } from "../../utils";
import headerArtist from "../../images/header-artist.jpg";
// import artistAor from "../../images/artist-aor.jpg";
// import artistMini from "../../images/artist-mini.jpg";
// import artistPosted from "../../images/artist-posted.jpg";
// import aristRs from "../../images/artist-rs.jpg";
// import iconGrad from "../../images/icon-grad.svg";
// import iconPaintbrush from "../../images/icon-paintbrush.svg";
// import iconCalendar from "../../images/icon-calendar.svg";

const SignUpArtist = () => {
    const [feedback, setFeedback] = useState({
        message: "",
        messageType: "",
      });
      const [email, setEmail] = useState("");
      const [fName, setFname] = useState("");
      const [lName, setLname] = useState("");
      const [phone, setPhone] = useState("");
    //   const [skype, setSkype] = useState("");
      const [facebook, setFacebook] = useState("");
      const [resume, setResume] = useState("");
      const [source, setSource] = useState("");
      const [why, setWhy] = useState("");
    // const scrollToSignup = () => {
    //   window.scrollTo(0, document.getElementById("signup-form").offsetTop);
    // }

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (fName !== '' && lName !== '' && email !== '' && facebook !== '') {
          const regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
          const form = document.querySelector('form');
          const data = new FormData(form);
    
          if (!regex.test(email)) {
              setFeedback({
                  message: 'Please enter a valid E-mail',
                  messageType: 'error'
              });
              return;
          } else {
              fetch("https://globalinheritance.us2.list-manage.com/subscribe/post-json?&c=?", {
                method: "POST",
                body: data,
                mode: 'no-cors',
              }).then(async (response) => {
                console.log(response);
                setFeedback({
                    message: 'Thank you! Your information has been sent',
                    messageType: 'success'
                });
              });
            }
      } else {
          setFeedback({
              message: 'Please fill out all fields',
              messageType: 'error'
          });
      }
      };

    return (
      <Layout navBg>
         <div className="signup-page">
         <div className="signup-inner">
         <Parallax className="page-header" bgImage={headerArtist} strength={400}>
              <div style={{height: '100vh', maxHeight: '550px'}}>
                  <div className="page-header-overlay" />
                  <h1 className="page-header-title white">Artist</h1>
              </div>
          </Parallax>
          <div className="signup-body">
          <div className="signup-intro">
              <p>Show off your art skills in support of the causes that matter most
                  to you! For over a decade, Global Inheritance and artists from around
                  the world have partnered together to inspire others through creative
                  platforms. Past initiatives include TRASHed :: Art of Recycling, Beat
                  The Press, POSTed, Fashion Peace, Portal Potties, and Rain Supreme.</p>

              <p>Artists are either selected by our internal team or through online art submissions.
                  Over 1,200 artists have contributed to this program.</p>

              <p>Fill out our Artist Forms, share artwork samples and concepts, and we will keep you in the
                  loop of upcoming projects and opportunities to get involved. Every year, Global Inheritance
                  brings new art-driven programs to major music festivals, universities and events across North
                  America. Join our artist network, and get involved today!</p>
          </div>
          <div className="form-container application-form-container" id="signup-form">
              {/* <iframe

                  className="airtable-embed"
                  src="https://airtable.com/embed/shrA2Ws3qwtkv0JaP?backgroundColor=purple"
                  frameBorder="0"
                  width="100%"
                  height="3700"
                  >
              </iframe> */}
             
              
                <div className="form-inner application-form-inner">
                    <h1>Join the Global Inheritance Artist Community</h1>
                    <p>*Required field</p>
                    <form name="applicationForm" className="form application-form app-volunteer" onSubmit={(e) => handleSubmit(e)}>
                        <input type="hidden" name="u" value="40e700f186279051f3a238f8a"/>
                        <input type="hidden" name="id" value="83440ad4be"/>
                        <input type="hidden" name="TYPE" id="MERGE3" value="VOLUNTEER" />
                        <div className="form-field application-field">
                            <input type="text" name="FNAME" placeholder="First Name" id="MERGE1" value={fName} onChange={(e)=>setFname(e.target.value) }/>
                            <label>FIRST NAME*</label>
                        </div>
                        <div className="form-field application-field">
                            <input type="text" name="LNAME" placeholder="Last Name" id="MERGE11" value={lName} onChange={(e)=>setLname(e.target.value)}/>
                            <label>LAST NAME*</label>
                        </div>
                        <div className="form-field application-field">
                            <input type="email" name="EMAIL" placeholder="Email" id="MERGE0" value={email}  onChange={(e)=>setEmail(e.target.value)} />
                            <label>EMAIL*</label>
                        </div>
                        <div className="form-field application-field">
                            <input type="text" name="PHONE" placeholder="Phone" id="MERGE8" value={phone}  onChange={(e)=>setPhone(e.target.value)}/>
                            <label>PHONE NUMBER</label>
                        </div>
            
                        <div className="form-field application-field">
                            <input type="text" name="FACEBOOK" id="MERGE1" placeholder="Social" value={facebook}  onChange={(e)=>setFacebook(e.target.value)} />
                            <label>INSTAGRAM (PUBLIC ACCOUNTS ONLY), FACEBOOK OR PHOTO URL*</label>
                        </div>
                        <div className="form-field application-field">
                            <input type="text" name="RESUME" id="MERGE7" placeholder="Resume" value={resume}  onChange={(e)=>setResume(e.target.value)} />
                            <label>LINK TO PORTFOLIO</label>
                        </div>
                        <div className="form-field application-field">
                            <input type="text" name="SOURCE" id="MERGE4" placeholder="How" value={source}  onChange={(e)=>setSource(e.target.value)}/>
                            <label>HOW DID YOU FIND OUT ABOUT US?</label>
                        </div>
                        <div className="form-field application-field">
                            <textarea name="WHY" id="MERGE8" rows={1} cols={1} value={why} maxLength={255} onChange={ (e)=>setWhy(e.target.value) } />
                            <label>WHAT WOULD MAKE YOU A GOOD FIT? (Character limit 255)</label>
                        </div>

                        {/* <div className="form-field application-field">
                            <ul>
                                <p>Cities you can volunteer in?*</p>
                                    <li><input type="checkbox" className="event" value="1" name="group[137][1]" id="mce-group[137]-137-0" /><span>Los Angeles</span></li>
                                    <li><input type="checkbox" className="event" value="2" name="group[137][2]" id="mce-group[137]-137-1" /><span>San Francisco</span></li>
                                    <li><input type="checkbox" className="event" value="4" name="group[137][4]" id="mce-group[137]-137-2" /><span>New York</span></li>
                                    <li><input type="checkbox" className="event" value="8" name="group[137][8]" id="mce-group[137]-137-3"/><span>Chicago</span></li>
                                    <li><input type="checkbox" className="event" value="16" name="group[137][16]" id="mce-group[137]-137-4"/><span>Miami</span></li>
                                    <li><input type="checkbox" className="event" value="32" name="group[137][32]" id="mce-group[137]-137-5"/><span>San Diego</span></li>
                                    <li><input type="checkbox" className="event" value="4194304" name="group[137][4194304]" id="mce-group[137]-137-6"/><span>Portland</span></li>
                                    <li><input type="checkbox" className="event" value="8388608" name="group[137][8388608]" id="mce-group[137]-137-7"/><span>Seattle</span></li>
                                    <li><input type="checkbox" className="event" value="16777216" name="group[137][16777216]" id="mce-group[137]-137-8"/><span>New Orleans</span></li>
                            </ul>
                        </div>

                        <div className="form-field application-field">
                            <ul>
                                <p>Which events would you be most interested in?*</p>
                                <li><input type="checkbox" className="event" value="64" name="group[205][64]" id="mce-group[205]-205-0"/><span>Coachella</span></li>
                                <li><input type="checkbox" className="event" value="128" name="group[205][128]" id="mce-group[205]-205-1"/><span>Stagecoach</span></li>
                                <li><input type="checkbox" className="event" value="256" name="group[205][256]" id="mce-group[205]-205-2"/><span>USC Spring Fest</span></li>
                                <li><input type="checkbox" className="event" value="512" name="group[205][512]" id="mce-group[205]-205-3"/><span>Firefly Music Festival</span></li>
                                <li><input type="checkbox" className="event" value="1024" name="group[205][1024]" id="mce-group[205]-205-4"/><span>Desert Daze</span></li>
                                <li><input type="checkbox" className="event" value="2048" name="group[205][2048]" id="mce-group[205]-205-5"/><span>Winter X Games</span></li>
                                <li><input type="checkbox" className="event" value="8192" name="group[205][8192]" id="mce-group[205]-205-6"/><span>Outside Lands Festival</span></li>
                                <li><input type="checkbox" className="event" value="16384" name="group[205][16384]" id="mce-group[205]-205-7"/><span>EDC LV</span></li>
                                <li><input type="checkbox" className="event" value="524288" name="group[205][524288]" id="mce-group[205]-205-8"/><span>Camp Flog Gnaw</span></li>
                                <li><input type="checkbox" className="event" value="1048576" name="group[205][1048576]" id="mce-group[205]-205-9"/><span>DesignerCon</span></li>
                                <li><input type="checkbox" className="event" value="2097152" name="group[205][2097152]" id="mce-group[205]-205-10"/><span>Waterfest</span></li>
                                <li><input type="checkbox" className="event" value="33554432" name="group[205][33554432]" id="mce-group[205]-205-11"/><span>Arroyo Seco Weekend</span></li>
                                <li><input type="checkbox" className="event" value="67108864" name="group[205][67108864]" id="mce-group[205]-205-12"/><span>Kappa Futur Festival</span></li>
                                <li><input type="checkbox" className="event" value="134217728" name="group[205][134217728]" id="mce-group[205]-205-13"/><span>New Orleans Jazz Festival</span></li>
                            </ul>
                        </div> */}

                        <div className="submit-container">
                            <button type="submit" className="btn-container btn primary">
                                <span>SUBMIT</span>
                            </button>
                        </div>

                        <div style={{position: 'absolute', left: '-5000px'}} aria-hidden='true' aria-label="Please leave the following three fields empty">
                            <label htmlFor="b_name">Name: </label>
                            <input type="text" name="b_name" tabIndex={-1} value="" placeholder="Freddie" id="b_name"/>

                            <label htmlFor="b_email">Email: </label>
                            <input type="email" name="b_email" tabIndex={-1} value="" placeholder="youremail@gmail.com" id="b_email"/>

                            <label htmlFor="b_comment">Comment: </label>
                            <textarea name="b_comment" tabIndex={-1} placeholder="Please comment" id="b_comment"></textarea>
                        </div>

                        <div className={`contact-message ${feedback.messageType}`} dangerouslySetInnerHTML={{__html: feedback.message}} />
                    </form>
                    </div>
                </div>
              </div>
          </div>
          </div>
      </Layout>
      
  )
}

export default SignUpArtist;
